





















import Vue from 'vue';

export const Work = Vue.extend({
	metaInfo: {
		title: 'Request Work',
	},
	mounted: function () {
		const link = document.createElement('link');
		const script = document.createElement('script');
		const div = document.createElement('div');
		div.id = '8ce15697-a01f-49cc-8b91-9b9929cabc15';
		div.className = 'hs-workForm';
		link.href = 'https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css';
		link.rel = 'stylesheet';
		script.src = 'https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js';
		script.setAttribute('clienthub_id', '8ce15697-a01f-49cc-8b91-9b9929cabc15');
		script.setAttribute(
			'form_url',
			'https://clienthub.getjobber.com/client_hubs/8ce15697-a01f-49cc-8b91-9b9929cabc15/public/work_request/embedded_work_request_form'
		);

		this.$el.appendChild(link);
		this.$el.appendChild(div);
		this.$el.appendChild(script);
	},
});

export default Work;
